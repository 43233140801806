<template>
  <div class="session">
    <v-container>
      <v-row justify="center" class="mt-12">
        <v-img :src="appLogo" max-width="280"></v-img>
      </v-row>
      <v-row justify="center" class="download">
        <v-col style="padding: 0px; margin-bottom: 2rem;">
          <v-card
            class="mx-auto"
            max-width="344"
            outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="overline mb-4">Versión 1.0.0 (a) - 13/12/2022</div>
                <v-list-item-title class="headline mb-1">
                  Android
                  <v-icon style="margin-bottom: 8px">mdi-android</v-icon>
                </v-list-item-title>
                <v-list-item-subtitle>Instalador APK para tu dispositivo</v-list-item-subtitle>
              </v-list-item-content>
              <td class="imagelink"><a :href="`${publicPath}download/app.apk`"><img height="57" src="https://dl.dropboxusercontent.com/s/4jf8kztykedvs0e/android-descarga.jpg" width="57" /></a></td>
            </v-list-item>

            <v-card-actions>
                <v-btn text :href="`${publicPath}download/app.apk`">
                  <v-icon style="margin-right: 1rem;">mdi-cloud-download</v-icon>
                    Descargar
                </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <!-- <v-col style="padding: 0px;">
          <v-card
            disabled
            class="mx-auto"
            max-width="344"
            outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="overline mb-4">Versión 0.0 - 30/04/2020</div>
                <v-list-item-title class="headline mb-1">
                  iOS
                  <v-icon style="margin-bottom: 8px">mdi-apple</v-icon>
                </v-list-item-title>
                <v-list-item-subtitle>Instalador IPA para tu dispositivo</v-list-item-subtitle>
              </v-list-item-content>
              <td class="imagelink"><a :href="`${publicPath}download/app-covidqa-release.apk`"><img height="57" src="https://cdn4.iconfinder.com/data/icons/apple-products-2026/512/Apple_Logo-512.png" width="57" /></a></td>
            </v-list-item>

            <v-card-actions>
              <v-btn text>
                <v-icon style="margin-right: 1rem;">mdi-cloud-download</v-icon>
                PROXIMAMENTE
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col> -->
      </v-row>
    </v-container>
  </div>
</template>
<script>
import AppConfig from '@/constants/app-config'
import { errorMessage } from '@/mixins'
export default {
  mixins: [errorMessage],
  data() {
    return {
      appLogo: AppConfig.appLogo,
      publicPath: process.env.BASE_URL
    }
  },
  components: {},
  methods: {}
}
</script>
<style>
.sub-text {
  width: 296px;
  font-size: 11px;
}
.download {
  margin-top: 5rem;
}
</style>
